/* Imports */
import { Formie } from '../../../vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib';

const formAttr = 'data-formie';

class Forms {
    constructor() {
        document.addEventListener('DOMContentLoaded', (event) => {
            this.initForms();
            this.submitForm();
        });
    }

    initForms() {
        const formieInstance = new Formie();
        formieInstance.initForms();

        document.addEventListener('onFormieInit', (event) => {
            // Fetch the Form Factory once it's been loaded
            let form = event.detail.formie;
            let formId = event.detail.formId;
            let formEl = document.getElementById(formId);

            // Refresh the necessary bits that are statically cached (CSRF inputs, captchas, etc)
            this.update(form, formId);

            if (formEl.getAttribute('data-form-submit-method') !== 'ajax') {
                formEl.addEventListener('onAfterFormieSubmit', (e) => {
                    //e.preventDefault();

                    this.update(form);
                });
            }
        });
    }

    submitForm() {
        const submitStoryUsername = document.querySelector('.submit-story-username');
        if (submitStoryUsername) {
            const submitStoryUsernameInput = submitStoryUsername.querySelector('input');
            const parentContainer = submitStoryUsernameInput.parentElement;
            const doublePeriodRegex = /\.{2,}/;

            submitStoryUsernameInput.addEventListener('keyup', () => {
                let usernameVal = submitStoryUsernameInput.value;
                let usernameLength = usernameVal.length;

                let usernameFirstChar = usernameVal[0];
                let usernameLastChar = usernameVal[usernameLength-1];

                let firstCheck = 0;

                if (usernameFirstChar === '.' || usernameLastChar === '.') {
                    // Username can't start or finish with a period
                    // This also stop usernames being all periods
                    this._submitStoryUsernameMessage(parentContainer, 'text-red-600', 'Your username can\'t start or finish with a period.');

                    firstCheck++;
                }
                else if (doublePeriodRegex.test(usernameVal)) {
                    // Username can't have 2 or more periods in between characters
                    // e.g. NOT ALLOWED `a..a` or `user..name....b`
                    this._submitStoryUsernameMessage(parentContainer, 'text-red-600', 'Your username can\'t contain 2 or more periods after another.');

                    firstCheck++;
                }

                if (!firstCheck) {
                    if (usernameLength >= 3 && usernameLength <= 30) {
                        parentContainer.classList.add('username-loading');

                        fetch('/api/v1/check-username.json?username=' + usernameVal, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                // If code is not available or invalid
                                if (data.response === 'available') {
                                    parentContainer.classList.remove('username-loading');
                                    parentContainer.classList.add('username-available');

                                    this._submitStoryUsernameMessage(parentContainer, 'text-green-500', 'Username is available');
                                } else {
                                    parentContainer.classList.remove('username-loading');
                                    parentContainer.classList.add('username-not-available');

                                    this._submitStoryUsernameMessage(parentContainer, 'text-red-600', 'Username is not available');
                                }
                            })
                            .catch((error) => {
                                console.error('Error:', error);
                                parentContainer.classList.remove('username-loading');
                            });
                    } else {
                        parentContainer.classList.remove('username-loading');

                        if (usernameLength > 0 && usernameLength < 3) {
                            this._submitStoryUsernameMessage(parentContainer, 'text-red-600', 'Username must be more than 3 characters');
                        } else if (usernameLength > 30) {
                            this._submitStoryUsernameMessage(parentContainer, 'text-red-600', 'Username must be less than 30 characters');
                        } else {
                            this._submitStoryUsernameMessage(parentContainer, '', '', true);
                        }
                    }
                }
            });
        }
    }

    _submitStoryUsernameMessage(el, messageClass, messageContent, forceHide = false) {
        const submitStoryUsernameClass = 'submit-story-username-message';
        const submitStoryUsernameMessage = el.querySelector('.' + submitStoryUsernameClass);

        // Remove message if it exists
        if (submitStoryUsernameMessage) {
            submitStoryUsernameMessage.remove();
        }

        if (!forceHide) {
            // Create a new element
            let messageDiv = document.createElement('div');

            // Set the inner HTML of the element
            messageDiv.innerHTML = '<p>' + messageContent + '</p>';

            // Add class to element
            messageDiv.classList.add('submit-story-username-message', 'mt-1', 'text-sm', messageClass);

            // Append the new div element to the target element
            el.appendChild(messageDiv);
        }
    }

    update(form, formId) {
        form.refreshForCache(formId);
    }
}

export default new Forms();
